<template>
  <div>
    <div style="margin: 10px 0">
      <el-date-picker
          v-model="startDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择起始日期"
      ></el-date-picker>
      <el-date-picker
          v-model="endDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择截止日期"
      ></el-date-picker>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>
    <div class="chart-container">
      <el-card style="width: 49%">
        <div ref="lineChart" class="chart"></div>
      </el-card>
      <el-card style="width: 49%">
        <div ref="lineChart2" class="chart"></div>
      </el-card>
    </div>
    <div class="chart-container">
      <el-card style="width: 49%">
        <div ref="lineChart3" class="chart"></div>
      </el-card>
      <el-card style="width: 49%">
        <div ref="lineChart4" class="chart"></div>
      </el-card>
    </div>
    <div class="chart-container">
      <el-card style="width: 49%">
        <div ref="lineChart5" class="chart"></div>
      </el-card>
      <el-card style="width: 49%">
        <div ref="lineChart6" class="chart"></div>
      </el-card>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "Allcost",
  data() {
    return {
      form: {},
      user: JSON.parse(localStorage.getItem('user') || '{}'),
      day: '',
      startDate: "",
      endDate: "",
      allSum: 0,
      allPirce: 0,
    }
  },
  created() {
    // 获取当前日期
    const currentDate = new Date();

    // 设置 endDate 为当前时间
    this.endDate = currentDate.toISOString();

    // 设置 startDate 为七天前的时间
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(currentDate.getDate() - 7);
    this.startDate = sevenDaysAgo.toISOString();

    // 调用加载方法
    this.load();
  },

  // mounted() {
  //   this.renderLineChart();
  //   this.renderLineChart2();
  // },
  methods: {
    load() {
      this.renderLineChart();
      this.renderLineChart2();
      this.renderLineChart3();
      this.renderLineChart4();
      this.renderLineChart5();
      this.renderLineChart6();
    },
    reset(){
      this.startDate = ""
      this.endDate = ""
      this.load()
    },
    // 渲染折线图
    renderLineChart() {
      this.request.get("/failureRecord/filterDateSum", {
        params: {
          start: this.startDate,
          end: this.endDate
        }
      }).then(res => {
        this.allSum = res.data;
      });

      this.request.get('/failureRecord/filterDate', {
        params: {
          start: this.startDate,
          end: this.endDate
        }
      }).then(response => {
        console.log('Response:', response);
        const daypro = response.data;

        if (daypro && Array.isArray(daypro)) {
          const dates = daypro.map(item => item.day);
          const counts = daypro.map(item => item.count);

          const lineChart = echarts.init(this.$refs.lineChart);
          const option = {
            title: {
              text: '工单数:'+this.allSum
            },
            xAxis: {
              type: 'category',
              data: dates
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                formatter: (value) => Math.floor(value)
              },
              minInterval: 1
            },
            series: [{
              data: counts,
              type: 'line',
              label: {
                show: true,         // 显示标签
                position: 'top',    // 标签显示在折线点上方
                formatter: '{c}'    // 格式化标签内容为坐标值
              }
            }]
          };
          lineChart.setOption(option);
        } else {
          console.error('Unexpected data format:', daypro);
        }
      }).catch(error => {
        console.error('Failed to fetch data:', error);
      });
    },
    renderLineChart2() {
      this.request.get('/recordComponent/filterDate', {
        params: {
          start: this.startDate,
          end: this.endDate
        }
      }).then(response => {
        console.log('Response:', response);
        const daypro = response.data;

        if (daypro && Array.isArray(daypro)) {
          const dates = daypro.map(item => item.day);
          const counts = daypro.map(item => item.price);
          this.allPrice = daypro[0].allPrice
          const lineChart2 = echarts.init(this.$refs.lineChart2);
          const option = {
            title: {
              text: '备件花费:'+ this.allPrice + '元'
            },
            xAxis: {
              type: 'category',
              data: dates
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                formatter: (value) => `${Math.floor(value)}元`
              },
              minInterval: 1
            },
            series: [{
              data: counts,
              type: 'line',
              label: {
                show: true,         // 显示标签
                position: 'top',    // 标签显示在折线点上方
                formatter: '{c}'    // 格式化标签内容为坐标值
              }
            }]
          };
          lineChart2.setOption(option);
        } else {
          console.error('Unexpected data format:', daypro);
        }
      }).catch(error => {
        console.error('Failed to fetch data:', error);
      });
    },
    renderLineChart3() {
      this.request.get('/failureRecord/getRecordType', {
        params: {
          start: this.startDate,
          end: this.endDate
        }
      }).then(response => {
        console.log('Response:', response);
        const daypro = response.data;

        if (daypro && Array.isArray(daypro)) {
          const dates = daypro.map(item => item.day);
          const counts = daypro.map(item => item.rate);

          const lineChart3 = echarts.init(this.$refs.lineChart3);
          const option = {
            title: {
              text: '应急维修率'
            },
            xAxis: {
              type: 'category',
              data: dates
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                formatter: (value) => `${Math.floor(value)}%`  // 在 Y 轴标签后添加百分号
              },
              minInterval: 1
            },
            series: [{
              data: counts,
              type: 'line',
              label: {
                show: true,         // 显示标签
                position: 'top',    // 标签显示在折线点上方
                formatter: '{c}%'   // 格式化标签内容为坐标值并添加百分号
              }
            }]
          };
          lineChart3.setOption(option);
        } else {
          console.error('Unexpected data format:', daypro);
        }
      }).catch(error => {
        console.error('Failed to fetch data:', error);
      });
    },
    renderLineChart4() {
      this.request.get('/failureRecord/getPMYield', {
        params: {
          start: this.startDate,
          end: this.endDate
        }
      }).then(response => {
        console.log('Response:', response);
        const daypro = response.data;

        if (daypro && Array.isArray(daypro)) {
          const dates = daypro.map(item => item.day);
          const counts = daypro.map(item => item.rate);

          const lineChart4 = echarts.init(this.$refs.lineChart4);
          const option = {
            title: {
              text: 'PM Yield'
            },
            xAxis: {
              type: 'category',
              data: dates
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                formatter: (value) => Math.floor(value)  // 在 Y 轴标签后添加百分号
              },
              minInterval: 1
            },
            series: [{
              data: counts,
              type: 'line',
              label: {
                show: true,         // 显示标签
                position: 'top',    // 标签显示在折线点上方
                formatter: '{c}'   // 格式化标签内容为坐标值并添加百分号
              }
            }]
          };
          lineChart4.setOption(option);
        } else {
          console.error('Unexpected data format:', daypro);
        }
      }).catch(error => {
        console.error('Failed to fetch data:', error);
      });
    },
    // renderLineChart5() {
    //   this.request.get('/equipment/getMTTR', {
    //     params: {
    //       start: this.startDate,
    //       end: this.endDate
    //     }
    //   }).then(response => {
    //     console.log('Response:', response);
    //     const daypro = response.data;
    //
    //     if (daypro && Array.isArray(daypro)) {
    //       const dates = daypro.map(item => item.day);
    //       const counts = daypro.map(item => item.rate);
    //
    //       const lineChart5 = echarts.init(this.$refs.lineChart5);
    //       const option = {
    //         title: {
    //           text: 'MTTR'
    //         },
    //         xAxis: {
    //           type: 'category',
    //           data: dates
    //         },
    //         yAxis: {
    //           type: 'value',
    //           axisLabel: {
    //             formatter: (value) => Math.floor(value)  // 在 Y 轴标签后添加百分号
    //           },
    //           minInterval: 1
    //         },
    //         series: [{
    //           data: counts,
    //           type: 'line',
    //           label: {
    //             show: true,         // 显示标签
    //             position: 'top',    // 标签显示在折线点上方
    //             formatter: '{c}'   // 格式化标签内容为坐标值并添加百分号
    //           }
    //         }]
    //       };
    //       lineChart5.setOption(option);
    //     } else {
    //       console.error('Unexpected data format:', daypro);
    //     }
    //   }).catch(error => {
    //     console.error('Failed to fetch data:', error);
    //   });
    // },
    // renderLineChart6() {
    //   this.request.get('/equipment/getMTBF', {
    //     params: {
    //       start: this.startDate,
    //       end: this.endDate
    //     }
    //   }).then(response => {
    //     console.log('Response:', response);
    //     const daypro = response.data;
    //
    //     if (daypro && Array.isArray(daypro)) {
    //       const dates = daypro.map(item => item.day);
    //       const counts = daypro.map(item => item.rate);
    //
    //       const lineChart6 = echarts.init(this.$refs.lineChart6);
    //       const option = {
    //         title: {
    //           text: 'MTBF'
    //         },
    //         xAxis: {
    //           type: 'category',
    //           data: dates
    //         },
    //         yAxis: {
    //           type: 'value',
    //           axisLabel: {
    //             formatter: (value) => Math.floor(value)  // 在 Y 轴标签后添加百分号
    //           },
    //           minInterval: 1
    //         },
    //         series: [{
    //           data: counts,
    //           type: 'line',
    //           label: {
    //             show: true,         // 显示标签
    //             position: 'top',    // 标签显示在折线点上方
    //             formatter: '{c}'   // 格式化标签内容为坐标值并添加百分号
    //           }
    //         }]
    //       };
    //       lineChart6.setOption(option);
    //     } else {
    //       console.error('Unexpected data format:', daypro);
    //     }
    //   }).catch(error => {
    //     console.error('Failed to fetch data:', error);
    //   });
    // },
  }
}
</script>
<style scoped>

.chart-container {
  display: flex;
  justify-content: space-between; /* 让两个卡片均匀分布 */
  align-items: flex-start; /* 让两个卡片顶部对齐 */
  width: 100%; /* 父容器宽度为100% */
}

.chart {
  width: 100%;
  height: 300px;
}

/*//.t_pagination_select {
//  .el-pagination {
//    display: flex;
//    background-color: #fff;
//    align-items: center;
//    .el-pagination__total,
//    .el-pager,
//    button {
//      display: flex;
//      align-items: center;
//    }
//  }
//}*/

</style>
